<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<div id="app">
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
import { auth } from '../firebaseConfig';
export default ({
	mounted() {
		window.addEventListener('unload', this.handleBeforeUnload)
		window.addEventListener('beforeunload', this.handleBeforeUnload)
		this.startInactivityTimer()
	},
	computed: {
		layout() {
			return "layout-" + (this.$route.meta.layout || "default").toLowerCase();
		}
	},
	methods: {
		handleBeforeUnload(event) {
			if (event.type === 'unload') { auth.signOut() }
		},

		startInactivityTimer() {
			let inactivityTimeout = null;
			const logoutAfterInactivity = () => {
				const requiresAuth = this.$route.meta.requiresAuth
				if (requiresAuth)
					this.$swal
						.fire({
							title: 'Confirmación',
							text: '¿Deseas continuar navegando?',
							icon: 'warning',
							showCancelButton: true,
							confirmButtonText: 'Aceptar',
							confirmButtonColor: "#45A825",
							cancelButtonText: 'Cancelar',
						})
						.then((result) => {
							if (!result.value) auth.signOut();
						});
			};
			const resetInactivityTimer = () => {
				clearTimeout(inactivityTimeout);
				inactivityTimeout = setTimeout(logoutAfterInactivity, 30 * 60 * 1000);
			};
			window.addEventListener('click', resetInactivityTimer);
			window.addEventListener('keydown', resetInactivityTimer);
			resetInactivityTimer();
		},
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		window.removeEventListener('unload', this.handleBeforeUnload);
	},
})

</script>