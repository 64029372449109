<template>
	<!-- Main Sidebar -->
	<a-layout-sider collapsible class="sider-primary" breakpoint="lg" collapsed-width="0" width="250px"
		:collapsed="sidebarCollapsed" @collapse="$emit('toggleSidebar', !sidebarCollapsed)" :trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]" theme="light"
		:style="{ backgroundColor: 'transparent', }">
		<div class="brand">
			<img src="../../../public/logoemsilvia.png" alt="Logo web site" class="logo-nav-admin">
			<!-- <div class="span-name-site-admin">
				<span>Empresa Municipal de Acueducto, Alcantarillado y Aseo de Silvia</span>
			</div> -->
		</div>
		<hr>

		<!-- Sidebar Navigation Menu -->
		<a-menu theme="light" mode="inline">
			<a-menu-item>
				<router-link to="/dashboard">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">Inicio</span>
				</router-link>
			</a-menu-item>
			<a-menu-item>
				<router-link to="/taips">
					<span class="icon">
						<a-icon type="unordered-list" theme="outlined" style="margin: auto;" />

						<!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
								fill="#111827" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
								fill="#111827" />
						</svg> -->
					</span>
					<span class="label">Taip</span>
				</router-link>
			</a-menu-item>
			<a-menu-item class="menu-item-header">
				Atencion y servicio a la ciudadania
			</a-menu-item>

			<!-- ! NO BORRAR -->
			<!-- <a-menu-item> 
				<router-link to="/billing">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z" fill="#111827" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">Duplicado de Factura</span>
				</router-link>
			</a-menu-item> -->

			<a-menu-item>
				<router-link to="/pqrs">
					<span class="icon">
						<a-icon type="unordered-list" theme="outlined" style="margin: auto;" />
						<!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z" fill="#111827" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
								fill="#111827" />
						</svg> -->
					</span>
					<span class="label">Pqrs</span>
				</router-link>
			</a-menu-item>
			<a-menu-item class="menu-item-header">
				Informate
			</a-menu-item>

			<a-menu-item>
				<router-link to="/News">
					<span class="icon">
						<a-icon type="unordered-list" theme="outlined" style="margin: auto;" />

						<!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z" fill="#111827" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
								fill="#111827" />
						</svg> -->
					</span>
					<span class="label">Noticias</span>
				</router-link>
			</a-menu-item>

			<a-menu-item>
				<router-link to="/Services">
					<span class="icon">
						<a-icon type="unordered-list" theme="outlined" style="margin: auto;" />
						<!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z" fill="#111827" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
								fill="#111827" />
						</svg> -->
					</span>
					<span class="label">Servicios</span>
				</router-link>
			</a-menu-item>

			<a-menu-item class="menu-item-header">
				Contratación
			</a-menu-item>

			<a-menu-item>
				<router-link to="/Tenders">
					<span class="icon">
						<a-icon type="unordered-list" theme="outlined" style="margin: auto;" />

						<!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z" fill="#111827" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
								fill="#111827" />
						</svg> -->
					</span>
					<span class="label">Invitaciones Públicas</span>
				</router-link>
			</a-menu-item>

			<a-menu-item class="menu-item-header">
				Utilidades
			</a-menu-item>

			<a-menu-item>
				<router-link to="/SlidersHome">
					<span class="icon">
						<a-icon type="unordered-list" theme="outlined" style="margin: auto;" />						
					</span>
					<span class="label">Slider Home</span>
				</router-link>
			</a-menu-item>

			<!-- !NO BORRAR -->
			<!-- <a-menu-item>
				<router-link to="/profile">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
								fill="#111827" />
						</svg>
					</span>
					<span class="label">Perfil</span>
				</router-link>
			</a-menu-item> -->
		</a-menu>
		<!-- / Sidebar Navigation Menu -->

		<!-- Sidebar Footer -->
		<!-- ? CONSIDERAR SI SE DEJA ESTE BLOQUE O NO -->
		<div class="aside-footer">
			<div class="footer-box">
				<span class="icon">
					<i class="el-icon-question" style="margin-right: 5px; color: black; margin: auto; font-size: 20px;"></i>
					<!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
							fill="#111827" />
						<path
							d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
							fill="#111827" />
						<path
							d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
							fill="#111827" />
					</svg> -->
				</span>
				<h6>¿Necesitas ayuda?</h6>
				<p>Por favor visita la documentación</p>
				<a-button type="primary" @click="centerDialogVisible = true">
					Documentación
				</a-button>
			</div>
		</div>

		<el-dialog title="Documentación" :visible.sync="centerDialogVisible" width="30%" center>
			<span>Por favor elige el manual de usuario que desea consultar</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="success" href="hola"
					@click="openDoc('https://firebasestorage.googleapis.com/v0/b/emsilvia-ded00.appspot.com/o/Documentation%2FManualEmSilviaPublic.pdf?alt=media&token=756e8460-43bf-4f4d-a689-35d0f21612a9')">Manual
					de usuario</el-button>
				<el-button type="success"
					@click="openDoc('https://firebasestorage.googleapis.com/v0/b/emsilvia-ded00.appspot.com/o/Documentation%2FManualEmSilviaAdmin.pdf?alt=media&token=97bf0897-d6c7-47b7-8273-39831c6bf491')">Manual
					de administrador</el-button>
			</span>
		</el-dialog>
	</a-layout-sider>
	<!-- / Main Sidebar -->
</template>

<script>

export default ({
	props: {
		// Sidebar collapsed status.
		sidebarCollapsed: {
			type: Boolean,
			default: false,
		},

		// Main sidebar color.
		sidebarColor: {
			type: String,
			default: "primary",
		},

		// Main sidebar theme : light, white, dark.
		sidebarTheme: {
			type: String,
			default: "light",
		},
	},
	data() {
		return {
			// sidebarCollapsedModel: this.sidebarCollapsed,
			centerDialogVisible: false
		}
	},
	methods: {
		openDoc(urlDoc) { window.open(urlDoc, '_blank'); }
	},
})

</script>

<style>
.logo-nav-admin {
	width: 100%;
	object-fit: contain;
	background-repeat: no-repeat;
	height: 100px !important;
}

.span-name-site-admin {
	width: 100%;
	color: rgb(49, 49, 49);
	text-align: start;
	font-size: 16px;
	font-weight: bold;
}</style>
