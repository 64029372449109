import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import "firebase/compat/auth";

// const firebaseConfig = {
//     apiKey: "AIzaSyDhl4QRs8qC1IGRQHxWaO0qG4-YaN-Ln7Y",
//     authDomain: "emsilvia-ded00.firebaseapp.com",
//     projectId: "emsilvia-ded00",
//     storageBucket: "emsilvia-ded00.appspot.com",
//     messagingSenderId: "668226093547",
//     appId: "1:668226093547:web:8f2a50439c04626992d9ae",
//     measurementId: "G-M9GSNZ702C"
// };


// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQB69F6wKYuyvMfG4wCRKnB-3PYI14gUI",
  authDomain: "emsilviaesp-34d13.firebaseapp.com",
  projectId: "emsilviaesp-34d13",
  storageBucket: "emsilviaesp-34d13.appspot.com",
  messagingSenderId: "1056631660399",
  appId: "1:1056631660399:web:978a0bace3b10e00b6929a",
  measurementId: "G-F45RRWSFBS"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const storage = firebase.storage()
const auth = firebase.auth()


export { db, storage, auth };

