<template>
	<!-- Layout Footer -->
	<a-layout-footer>
		<a-row type="flex">
			<a-col :span="24" :md="12">

				<!-- Footer Copyright Notice -->
				<p class="copyright">
					© 2023, EmSilvia creators <a href="https://github.com/GuillermoSamboni">@Guillermo Samboni</a> and <a
						href="https://github.com/dhurtado0609">@David Hurtado</a>
				</p>
				<!-- / Footer Copyright Notice -->

			</a-col>
			<a-col :span="24" :md="12" class="footer-control">

				<!-- Footer Navigation Menu -->
				<!-- !NO BORRAR -->
				<!-- <a-menu mode="horizontal">
					<a-menu-item>Github</a-menu-item>
					<a-menu-item>Linkedin</a-menu-item>					
				</a-menu> -->
				<!-- / Footer Navigation Menu -->
			</a-col>
		</a-row>
	</a-layout-footer>
	<!-- / Layout Footer -->
</template>

<script>

export default ({
	data() {
		return {
		}
	},
})

</script>
