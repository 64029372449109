<template>
    <div class="cz_middle_footer">
        <div class="row2 clr">
            <div class="col2 s4 sidebar_footer-1 clr">
                <div id="codevz_about-3" class="center_on_mobile footer_widget clr codevz-widget-about">
                    <div class="tal">
                        <img class="mb30 lazyDone" src="@/assets/img/logoEMSILVIAFooter.png" data-src="" width="168"
                            height="76" alt="Logo" style="width: 168px" />
                    </div>
                </div>
                <div id="text-9" class="center_on_mobile footer_widget clr widget_text">
                    <h4>Contáctenos</h4>
                    <div class="textwidget">
                        <ul class="themesflat_information">
                            <li>
                                <i class='fa-solid fa-location-dot'></i> Cra: 3a No.15-02 B/ San Agustin, Colina de Belen
                            </li>
                            <li>
                                <i class='fa fa-phone'></i> Cel: 3105034709
                            </li>
                            <li>
                                <i class='fas fa-mail-bulk'></i> Email:
                                emsilviaesp@gmail.com
                            </li>
                            <li>
                                <i class='fas fa-clock'></i> Lun – Viernes: 7:00 a.m a
                                03:00 p.m.
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="col2 s4 sidebar_footer-2 clr">
                <div id="text-10" class="center_on_mobile footer_widget clr widget_text">
                    <h4>Enlaces de Interés</h4>
                    <div class="textwidget">
                        <p>
                            <a style="font-size: 12px" href="http://www.silvia-cauca.gov.co/" target="_blank" role="link">Alcaldía de Silvia
                                Cauca</a><br />

                            <a style="font-size: 12px" href="http://www.superservicios.gov.co/" target="_blank" role="link">Superintendencia
                                de
                                Servicios Publicos Domiciliarios</a><br />

                            <a style="font-size: 12px" href="https://www.pdacauca.gov.co/" target="_blank" role="link">Empresa Caucana de
                                Servicios
                                Publicos</a><br />


                            <a style="font-size: 12px" href="http://cra.gov.co/" target="_blank" role="link">Comisión de Regulación de Agua
                                Potable y
                                Saneamiento Básico
                                CRA</a><br />
                            <a style="font-size: 12px" href="https://www.contraloria-cauca.gov.co/" target="_blank" role="link">Contraloria
                                General del
                                Cauca</a><br />

                        </p>
                        <p>
                            <img decoding="async" class="wp-image-11114 alignleft lazyDone"
                                src="https://emquilichao.gov.co/wp-content/uploads/2022/06/logo-gov-1024x221.png"
                                data-src="" alt="" width="190" height="41" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="col2 s4 sidebar_footer-3 clr">
                <div id="text-11" class="center_on_mobile footer_widget clr widget_text">
                    <h4>Redes Sociales</h4>
                    <div class="textwidget">
                        <p>
                            <i class="fa-brands fa-twitter" aria-hidden="true"></i><a style="font-size: 12px" href="https://twitter.com/EmsilviaESP"
                                target="”_blank”" rel="noopener" role="link">
                                Twitter</a><br />
                            <i class="fa-brands fa-facebook-f" aria-hidden="true"></i><a style="font-size: 12px" href="https://www.facebook.com/profile.php?id=100093843576095"
                                target="_blank" rel="noopener" role="link">
                                Facebook</a><br />
                            <i class="fa-brands fa-instagram" aria-hidden="true"></i><a style="font-size: 12px" href="https://www.instagram.com/emsilvia_esp/"
                                target="”_blank”" rel="noopener" role="link">
                                Instagram</a>
                        </p>
                    </div>
                </div>
                <div id="text-12" class="center_on_mobile footer_widget clr widget_text">
                    <div class="textwidget">
                        <div class="company-description">
                            <p>
                                <b>Última Actualización:</b><br />
                                martes 23 mayo 2023, 17.10.51
                            </p>
                        </div>
                    </div>
                </div>
                <div id="text-13" class="center_on_mobile footer_widget clr widget_text">
                    <div class="textwidget">
                        <hr style="size: 8px; color: white" />
                        <p>
                            <a style="font-size: 12px"
                                href="https://firebasestorage.googleapis.com/v0/b/emsilvia-ded00.appspot.com/o/politicaDatos%20personales%2FPOLITICA%20DE%20TRATAMIENTO%20DE%20DATOS%20EMSILVIA%20ESP.pdf?alt=media&token=b15d0105-b9a8-45ff-a854-3cef167c0801"
                                target="”_blank”" rel="noopener" role="link">
                                Política Tratamiento de datos personales</a><br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_2 have_center smart_sticky">
            <div class="row2 elms_row">
                <div class="clr">
                    <div class="elms_center footer_2_center">
                        <div>
                            <div class="cz_elm icon_footer_2_center_13 inner_icon_footer_2_center_0"
                                style="margin-top:30px;margin-bottom:25px;">
                                <div class="elm_icon_text"><span class="it_text "
                                        style="font-size:15px;color:rgba(255,255,255,0.8);">
                                        Empresa Municipal de Acueducto, Alcantarillado y Aseo de Silvia-Cauca</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() { }
}
</script>
  
<style lang="css">
.cz_middle_footer {
    background-color: #033981;
    padding-top: 60px;

}

footer .row2 {
    max-width: 100%;
}

.row2 {
    width: 1280px;
    max-width: 95%;
    position: relative;
    margin: 0 auto;
}

@media screen and (max-width: 1240px) {
    .row2 {
        width: 90% !important;
        padding: 0;
    }
}

.clr:after,
.gallery:after {
    clear: both;
}

.clr:before,
.clr:after,
.gallery:before,
.gallery:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clr:before {

    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;

}

.col2:first-child,
.col2_first {
    margin-left: 0;
}

.s4 {
    width: 30.66%;
}

.col2 {
    display: block;
    float: left;
    margin-left: 4%;

}

@media(max-width:700px) {
    .col2 {
        width: 100% !important;
        margin: 0 !important;
        border-radius: 0;
    }
}

@media(max-width:700px) {
    .center_on_mobile {
        text-align: center !important;
        float: none !important;
        list-style: none !important;
    }

    .center_on_mobile img {
        display: table !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

}

@media(max-width:700px) {
    .center_on_mobile * {
        text-align: center !important;
        float: none !important;
        list-style: none !important;
    }
}

.footer_widget {
    font-size: 14px;
    color: #ffffff;
    padding: 0px;
    position: relative;
    margin: 0 0 30px;
    z-index: 1;
    transition: all .2s ease-in-out;
}


.tal {
    text-align: center !important;
    margin-left: 40px;
}

@media(max-width:700px) {
    .tal {
        margin-left: 0px;
    }

}

.codevz-widget-about>.tal>* {
    text-align: left;
    display: table;
    margin-right: auto;
}

img.lazyDone {
    width: auto;
    background-color: transparent;
    background-image: none !important;
    opacity: 1;
}


.mb30 {
    margin-bottom: 30px;
}

img {
    max-width: 100%;
    display: block;
    height: auto;
    box-sizing: border-box;
}

.footer_widget>h4 {
    position: relative;
    border-bottom: 1px dashed rgba(167, 167, 167, .2);
    padding: 0 0 20px;
    margin: 0 0 20px;
}

.footer_widget>h4,
footer .widget_block>div>div>h2 {
    color: #ffffff;
    font-size: 28px;
    font-weight: 100;
    border-style: solid;
    border-width: 0 0 1px;
}

.footer_widget>h4:after,
.footer_widget>h4:before {

    position: absolute;
    content: '';
    width: 30%;
    height: 6px;
    left: 0;
    bottom: -1px;
}


.themesflat_information {
    list-style-type: disc;
    box-sizing: border-box;
}

@media(max-width:700px) {
    .themesflat_information {
        padding-left: 0px;
    }
}


p {
    margin-bottom: 30px;
}

p:last-child {
    margin-bottom: 0;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

.cz_middle_footer a {
    color: #ffffff;
    line-height: 2;
}

.footer_2 {
    background-color: #04a43e;
    padding-top: 0px;
}

footer .elms_row {
    z-index: 1;
}

.have_center>.elms_row>.clr {
    display: flex;
}

.elms_row>.clr {
    width: 100%;
    position: relative;
}

.have_center .elms_center {

    flex: 1 0 20%;
    display: block;
    width: 100%;
}

.elms_center {

    position: relative;
    vertical-align: top;
}

.elms_center>div {
    margin: 0 auto;
    display: table;
}

.elms_center>div>div {
    float: left;
}

.cz_elm {


    position: relative;
    font-size: 16px;
}

.elm_icon_text {
    border: 0;
}

@media(max-width:700px) {
    .elm_icon_text {
        text-align: center;
    }
}

.elm_icon_text>span {
    display: inline-block;
    vertical-align: middle;
    transition: all .2s ease-in-out;
}</style>
  