<template>
    <div>
        <a-layout-header class="a-layout-header">

            <div class="header-brand">
                
                <router-link to="/inicio">
                    <img class="log-img" src="../../assets/img/logoemsilvia - copia.png" alt="">
                </router-link>
            </div>
            <div class="header-brand-down">
                <a-button type="link" class="sidebar-toggler" @click="navbarCollapsed = !navbarCollapsed">
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
                    </svg>
                </a-button>
            </div>
            <!-- Menu Large -->
            <div class="header-col-large header-nav">
                <a-menu mode="horizontal" class="a-menu">
                    <a-menu-item class="a-menu-item">
                        <router-link to="/">
                            <span>Inicio</span>
                        </router-link>
                    </a-menu-item>

                    <a-menu-item class="a-menu-item">
                        <router-link to="/publicTaips">
                            <span>Transparencia y acceso información pública</span>
                        </router-link>
                    </a-menu-item>

                    <a-menu-item class="a-menu-item">
                        <span>Atención y servicio a la ciudadanía</span>
                        <div class="sub-menu">
                            <a-menu-item>
                                <router-link to="/publicPqrs">
                                    <span>PQRS recepción de solicitudes</span>
                                </router-link>
                            </a-menu-item>
                            <a-menu-item>
                                <router-link to="/publicServices">
                                    <span>Servicios</span>
                                </router-link>
                            </a-menu-item>
                        </div>
                    </a-menu-item>
                    <a-menu-item class="a-menu-item">
                        <span>Infórmate</span>
                        <div class="sub-menu">
                            <a-menu-item>
                                <router-link to="/publicNews">
                                    <span>Noticias</span>
                                </router-link>
                            </a-menu-item>
                        </div>
                    </a-menu-item>
                    <a-menu-item class="a-menu-item">
                        <span>Contratación</span>
                        <div class="sub-menu">
                            <a-menu-item>
                                <router-link to="/publicTenders">
                                    <span>Invitaciones Públicas</span>
                                </router-link>
                            </a-menu-item>
                        </div>
                    </a-menu-item>

                    <a-menu-item class="a-menu-item">
                        <router-link to="/sign-in">
                            <i class="el-icon-user" style="margin-right: 5px; color: black;"></i>
                            <span>Acceder</span>
                        </router-link>
                    </a-menu-item>
                </a-menu>
            </div>
            <!-- End menu Large -->
        </a-layout-header>


        <!-- Menu down -->
        <div class="container-menu-short" v-if="this.navbarCollapsed">
            <a-menu mode="vertical" class="a-menu">
                <a-menu-item class="a-menu-item">
                    <router-link to="/">
                        <span>Inicio</span>
                    </router-link>
                </a-menu-item>

                <a-menu-item class="a-menu-item">
                    <router-link to="/publicTaips">
                        <span>Transparencia y acceso información pública</span>
                    </router-link>
                </a-menu-item>

                <a-menu-item class="a-menu-item">
                    <router-link to="/publicPqrs">
                        <span>PQRS recepsión de solicitudes</span>
                    </router-link>
                </a-menu-item>

                <a-menu-item class="a-menu-item">
                    <router-link to="/publicServices">
                        <span>Servicios</span>
                    </router-link>
                </a-menu-item>

                <a-menu-item class="a-menu-item">
                    <router-link to="/publicNews">
                        <span>Noticias</span>
                    </router-link>
                </a-menu-item>


                <a-menu-item class="a-menu-item">
                    <router-link to="/publicTenders">
                        <span>Licitaciones</span>
                    </router-link>
                </a-menu-item>

                <a-menu-item class="a-menu-item">
                    <router-link to="/sign-in">
                        <i class="el-icon-user" style="margin-right: 5px; color: black;"></i>
                        <span>Acceder</span>
                    </router-link>
                </a-menu-item>
            </a-menu>
        </div>

        <!-- End menu down -->
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            navbarCollapsed: false
        }
    },
    mounted() {
        window.addEventListener('resize', this.checkScreenSize);
        this.checkScreenSize();
    },
    methods: {
        checkScreenSize() {
            if (this.navbarCollapsed)
                this.navbarCollapsed = window.innerWidth <= 1180;
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    }

}
</script>

<style lang="scss" scoped>
.log-img {

    width: 90px;
    height: 70px;
    margin-top: -2px;
    margin-right: 5px;

}

.header-brand {
    display: flex;

}



.a-layout-header {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.a-layout-header-down {
    // background-color: red;    
    width: 100%;
}

.header-brand-down {
    display: none;
}

.header-col-large {
    display: flex;
}

.nav-link svg {
    margin-right: 5px;
    vertical-align: middle;
}

.nav-link span {
    vertical-align: middle;
}

.ant-menu-submenu-popup {
    width: 100%;
}

.a-menu {
    margin: auto;
    border: transparent;
}

.a-menu-item {
    position: relative;
    margin-right: 10px;
}

.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    width: 119%;
    padding: 10px;
    display: none;
    z-index: 999;
}

.a-menu-item:hover .sub-menu {
    display: block;
    transition: all 3s;
}

.container-menu-short {
    // height: 300px;
    width: 100%;
    background-color: red;
}

@media (max-width: 1180px) {
    .header-col-large {
        display: none;
    }

    .header-brand-down {
        display: inline-block;
        font-size: 30px;
        color: black;
    }

    .a-layout-header {
        padding-right: 10px;
        padding-left: 10px;
    }
}
</style>
