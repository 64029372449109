import Vue from "vue";
import VueRouter from "vue-router";
import db from "../../firebaseConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { auth } from "../../firebaseConfig";

Vue.use(VueRouter);

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/Inicio",
  },
  {
    path: "/inicio",
    name: "Inicio",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "dashboardPublic" */ "../views/DashboardPublic.vue"
      ),
  },
  {
    path: "/publicTaips",
    name: "publicTaips",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "publicTaips " */ "../views/public/taips/Taips.vue"
      ),
  },
  {
    path: "/misionVision",
    name: "misionVision",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "misionVision " */ "../views/public/taips/MisionVision.vue"
      ),
  },
  {
    path: "/publicPqrs",
    name: "publicPqrs",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "publicPqrs " */ "../views/public/attention/Pqrs.vue"
      ),
  },
  {
    path: "/publicNews",
    name: "publicNews",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "publicNews " */ "../views/public/news/News.vue"
      ),
  },
  {
    path: "/publicDetailNew/:_id",
    name: "publicDetailNew",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "publicDetailNew " */ "../views/public/news/DetailNew.vue"
      ),
  },
  {
    path: "/publicServices",
    name: "publicServices",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "publicServices " */ "../views/public/services/Services.vue"
      ),
  },
  {
    path: "/publicTenders",
    name: "publicTenders",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "publicTenders " */ "../views/public/tenders/Tenders.vue"
      ),
  },
  {
    path: "/descriptionTenders/:_id",
    name: "descriptionTenders",
    layout: "public",
    component: () =>
      import(
        /* webpackChunkName: "descriptionTenders " */ "../views/public/tenders/DescriptionTender.vue"
      ),
  },

  // ADMIN ROUTES
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "dashboard",

    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/taips",
    name: "taips",
    layout: "dashboard",
    component: () => import("../views/admin/taips/Taips.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/registerTaip",
    name: "registerTaip",
    layout: "dashboard",
    component: () => import("../views/admin/taips/Register.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/detailTaip/:_id",
    name: "detailTaip",
    layout: "dashboard",
    component: () => import("../views/admin/taips/Detail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/editTaip/:_id",
    name: "editTaip",
    layout: "dashboard",
    component: () => import("../views/admin/taips/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pqrs",
    name: "pqrs",
    layout: "dashboard",
    component: () => import("../views/admin/pqrs/Pqrs.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/detailPqr/:_id",
    name: "detailPqr",
    layout: "dashboard",
    component: () => import("../views/admin/pqrs/Detail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/News",
    name: "News",
    layout: "dashboard",
    component: () => import("../views/admin/news/News.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/RegisterNew",
    name: "RegisterNew",
    layout: "dashboard",
    component: () => import("../views/admin/news/Register.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/EditNew/:_id",
    name: "EditNew",
    layout: "dashboard",
    component: () => import("../views/admin/news/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/DetailNew/:_id",
    name: "DetailNew",
    layout: "dashboard",
    component: () => import("../views/admin/news/Detail.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/Services",
    name: "Services",
    layout: "dashboard",
    component: () => import("../views/admin/services/Services.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/RegisterService",
    name: "RegisterService",
    layout: "dashboard",
    component: () => import("../views/admin/services/Register.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/editService/:_id/:_type_service",
    name: "editService",
    layout: "dashboard",
    component: () => import("../views/admin/services/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Tenders",
    name: "Tenders",
    layout: "dashboard",
    component: () => import("../views/admin/tenders/Tenders.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/RegisterTender",
    name: "RegisterTender",
    layout: "dashboard",
    component: () => import("../views/admin/tenders/Register.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/detailTender/:_id",
    name: "detailTender",
    layout: "dashboard",
    component: () => import("../views/admin/tenders/Detail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/editTender/:_id",
    name: "editTender",
    layout: "dashboard",
    component: () => import("../views/admin/tenders/Edit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/SlidersHome",
    name: "SlidersHome",
    layout: "dashboard",
    component: () => import("../views/admin/slidersHome/Sliders.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/layout",
    name: "Layout",
    layout: "dashboard",
    component: () => import("../views/Layout.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tables",
    name: "Tables",
    layout: "dashboard",
    component: () => import("../views/Tables.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/billing",
    name: "Billing",
    layout: "dashboard",
    component: () => import("../views/Billing.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Profile",
    name: "Profile",
    layout: "dashboard",
    meta: {
      layoutClass: "layout-profile",
    },
    component: () => import("../views/Profile.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/sign-in",
    name: "Sign-In",
    layout: "default",
    component: () => import("../views/Sign-In.vue"),
  },
  {
    path: "/sign-up",
    name: "Sign-Up",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Sign-Up.vue"),
  },

  {
    path: "/Mecard",
    name: "Mecard",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../components/Cards/CardProjectTable2.vue"),
    meta: { requiresAuth: true },
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        next();
      } else {
        next({ path: "/sign-in" });
      }
    });
  } else {
    next();
  }
});

export default router;
