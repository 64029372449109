<template>
    <div>
        <a-layout class="layout-public" id="layout-public" :class="[layoutClass]">
            <!-- Layout Header ( Navbar ) -->
            <PublicNav></PublicNav>
            <!-- Layout Header ( Navbar ) -->

            <!-- Page Content -->
            <a-layout-content>
                <router-view />
            </a-layout-content>
            <!-- / Page Content -->

            <!-- Layout Footer -->
            <FooterPublic></FooterPublic>
            <!-- / Layout Footer -->

        </a-layout>
    </div>
</template>

<script>
import PublicNav from '../components/navs/PublicNav.vue';
import FooterPublic from '../components/Footers/FooterPublic.vue';

export default {
    components: {
        PublicNav,
        FooterPublic
    },
    data() {
        return {
        }
    },
    computed: {
        // Sets layout's element's class based on route's meta data.
        layoutClass() {
            return this.$route.meta.layoutClass;
        }
    },
    mounted() {
    },
}
</script>
<style>
.layout-public {
    background-color: #eaf5fd;
}
</style>