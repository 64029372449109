import Vue from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import DefaultLayout from "./layouts/Default.vue";
import DashboardLayout from "./layouts/Dashboard.vue";
import DashboardPublicLayout from "./layouts/DashboardPublic.vue";
import router from "./router";
import "./scss/app.scss";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./css/base/Index.css";
import "./css/base/IndexPublic.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Antd);
Vue.use(VueSweetalert2);
Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.component("layout-default", DefaultLayout); //Layouth Auth
Vue.component("layout-dashboard", DashboardLayout); //Layout HomeAdmin
Vue.component("layout-public", DashboardPublicLayout); //Layout HomePublic

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
